<template>
  <div>
    <iframe :src="pdfUrl" style="width: 100%;height: 100vh"></iframe>
<!--    <canvas ref="pdfCanvas"></canvas>-->
  </div>
</template>

<script>
//import pdfjs from 'pdfjs-dist';

export default {
  props: {
    pdfUrl: {
      type: String,
      required: true
    }
  },
  mounted() {
    //this.loadPdf();
  },
  methods: {
    // loadPdf() {
    //   console.log('pdfurl:',this.pdfUrl);
    //   const canvas = this.$refs.pdfCanvas;
    //   const ctx = canvas.getContext('2d');
    //   //const loadingTask = pdfjs.getDocument(this.pdfUrl);
    //   const loadingTask = pdfjs.getDocument({url: this.pdfUrl, httpHeaders: {'Access-Control-Allow-Origin': '*'}});
    //
    //   loadingTask.promise.then(pdf => {
    //     console.log('PDF loaded');
    //     pdf.getPage(1).then(page => {
    //       const viewport = page.getViewport({ scale: 1.5 });
    //       canvas.height = viewport.height;
    //       canvas.width = viewport.width;
    //       const renderContext = {
    //         canvasContext: ctx,
    //         viewport: viewport
    //       };
    //       page.render(renderContext).promise.then(() => {
    //         console.log('Page rendered');
    //       });
    //     }).catch(err => {
    //       console.error('Cannot load page: ', err);
    //     });
    //   }).catch(err => {
    //     console.error('Error loading PDF: ', err);
    //   });
    // }
  }
};
</script>