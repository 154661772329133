<template>
  <Header/>
  <!--内容-->
  <el-form ref="form" size="large" :rules="rules" :model="form" @keyup.enter.down="keyDown">
    <div class="page-publish-works">
      <div class="title">发作品</div>
      <div class="content-area gk-clear">
        <div class="dbcont gk-clear">
          <div class="left-area">
            <div class="item">
              <div class="label">
                <div class="tit"><span>*</span>作品源文件</div>
              </div>
              <div class="upload-area">
                <el-upload v-model="files" :action="upload_url" :headers="headers" :limit="1" :on-success="handleSuccess">
                  <img src="/static/iconImg/upload-cloud-line.png" width="30" alt="">
                  <span class="span01">点击或直接将pdf拖入此区域上传</span>
                  <span class="span02">pdf格式，大小不超过20M</span>
                </el-upload>
              </div>
            </div>
          </div>
          <div class="right-area">
            <div class="item">
            <div class="label">
                <div class="tit"><span>*</span>作品封面</div>
              </div>
            <el-form-item prop="img" class="upload-area">
            <el-upload :action="upload_url" :headers="headers" :on-success="handleImgSuccess" :show-file-list="false">
              <el-image v-if="form.img == ''" src="/static/iconImg/icon-add02.png" style="width:26px;height:26px;" lazy></el-image>
              <el-image v-else :src="form.img" fit="scale-down" style="width:366px;height:229px" lazy>
                <!-- <template #error>
                  <el-image src="/static/iconImg/icon-add02.png" style="width:26px !important;height:26px !important;" lazy></el-image>
                </template> -->               
              </el-image>
            </el-upload>
            <span v-if="form.img == ''">点击+号上传封面(建议尺寸366*229px)</span>
          </el-form-item>
        </div>
          </div>
        </div>
        <div class="dbcont gk-clear">
          <div class="left-area">
            <div class="item">
            <div class="label">
              <div class="tit"><span>*</span>作品标题</div>
            </div>
            <el-form-item prop="title" class="input-area df">
              <el-input v-model="form.title" maxlength="50" placeholder="请输入作品标题" show-word-limit type="text" clearable/>
            </el-form-item>
          </div>
          </div>
          <div class="right-area">
            <div class="item">
            <div class="label">
              <div class="tit"><span>*</span>行业类型</div>
            </div>
            <el-form-item prop="business_id" class="input-area df">
              <el-select v-model="form.business_id" filterable placeholder="请选择行业" style="width: 100%"
                         size="large">
                <el-option v-for="item in business" :key="item.id" :label="item.name" :value="item.id"/>
              </el-select>
            </el-form-item>
          </div>
          </div>
        </div>
        <div class="dbcont">
          <div class="item">
            <div class="label">
              <div class="tit"><span>*</span>作品标签</div>
            </div>
            <el-form-item prop="tags" class="textarea-area">
              <el-input v-model="form.tags" style="width: 100%;height: 100px;" maxlength="200"
                        placeholder="请输入作品标签，多个标签请使用分号“;”隔开。" show-word-limit type="textarea"/>
            </el-form-item>
          </div>
        </div>
        <div class="dbcont">
          <div class="item">
            <div class="label">
              <div class="tit"><span>*</span>作品描述</div>
            </div>
            <el-form-item prop="description" class="textarea-area">
              <el-input v-model="form.description" style="width: 100%;height: 100px;" maxlength="300"
                        placeholder="请输入作品描述" show-word-limit type="textarea"/>
            </el-form-item>
          </div>
        </div>
        <div class="dbcont">
          <div class="item">
            <div class="label label-tag">
              <div class="tit">选择品牌</div>
            </div>
            <el-form-item prop="brand_id" class="input-area df">
              <el-select v-model="form.brand_id" filterable placeholder="请选择品牌" style="width: 100%" size="large">
                <el-option v-for="item in brand" :key="item.id" :label="item.name" :value="item.id"/>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="dbcont gk-clear">
          <div class="left-area">
            <div class="item">
            <div class="label">
              <div class="tit"><span>*</span>选择类型</div>
            </div>
            <div class="industry-type">
              <el-form-item prop="cid" class="input-area df">
                <el-select v-model="form.cid" filterable placeholder="请选择类型" style="width: 100%;" size="large">
                  <el-option v-for="item in type" :key="item.id" :label="item.name" :value="item.id"/>
                </el-select>
              </el-form-item>
            </div>
          </div>
          </div>
          <div class="right-area">
            <div class="item">
            <div class="label">
              <div class="tit"><span>*</span>源文件定价</div>
            </div>
            <el-form-item prop="price" class="input-area df price">
              <el-input v-model="form.price" style="width: 100%;height: 40px;" placeholder="请输入作品定价" type="text" :formatter="(value) => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                        :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"/>
            </el-form-item>
            <div class="tips">
              <span>平台与上传者 5:5 分佣，收益即时到账</span>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </el-form>
  <!--底部发布按钮区域-->
  <div class="publish-btn-area">
    <div class="content-area">
      <div class="left">
        <label for="">
          <input type="checkbox" checked>我已阅读并同意
          <a target="_blank"></a>
          <el-link href="/page/share" target="_blank">《ikeyhub咋办签约作品分享合作协议》</el-link>
        </label>
      </div>
      <div class="right">
        <div class="btn btn-view" @click="showPreview">预览</div>
        <div class="btn btn-publish" @click="submit">发布作品</div>
      </div>
    </div>
  </div>
  <el-drawer v-model="previewVisible" title="作品预览" :destroy-on-close="true" :close-on-click-modal="false" size="100%" z-index="1001">
    <detail-preview :detail="form"></detail-preview>
  </el-drawer>
</template>
<script>
import Header from "@/layout/components/header";
import DetailPreview from "@/views/user/detail_preview";
import article from "@/api/article";
import attachment from "@/api/public";
import {getToken} from "@/utils/auth";


export default {
  name: "publish",
  components: {Header,DetailPreview},
  data() {
    return {
      upload_url: '',
      headers: {"Authorization": getToken()},
      loading: false,
      allCats: [],
      type: [],
      business: [],
      brand: [],
      files: {},
      previewVisible:false,
      form: {
        id: 0,
        cid: '',
        business_id: '',
        brand_id: '',
        title: '',
        keywords: '',
        description: '',
        tags: '',
        content: '',
        author: '',
        img:'',
        file: '',
        price: '0.00',
        is_draft: 0,
        view:0,
        very_good:0,
        comment:0
      },
      rules: {
        title: [{required: true, message: '请输入作品标题', trigger: 'blur'}, {
          min: 6,
          max: 50,
          message: '作品标题长度在 6 到 50 个字符',
          trigger: 'blur'
        }],
        tags: [{required: true, message: '请输入作品标签', trigger: 'blur'}, {
          min: 5, max: 200, message: '内容长度在 5 到 200 个字符', trigger: 'blur' }],
        description: [{required: true, message: '请输入作品描述', trigger: 'blur'}, {
          min: 5, max: 300, message: '内容长度在 5 到 300 个字符', trigger: 'blur' }],
        cid: [{required: true, message: '请选择类型', trigger: 'blur'}],
        business_id: [{required: true, message: '请选择行业', trigger: 'blur'}],
        brand_id: [{required: true, message: '请选择品牌', trigger: 'blur'}],
        img: [{required: true, message: '请上传作品封面'}],
      }
    }
  },
  created() {
    attachment.uploadFileUrl().then(res => {
      this.upload_url = res;
    });
    //this.getCategory();
    this.getType();
    this.getBusiness();
    this.getBrand();
  },
  methods: {
    //展示预览页面
    showPreview(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.previewVisible = true;
        } else {
          return false
        }
      })
    },
    //上传成功
    handleSuccess(res) {
      if (res.code == 1) {
        this.$message.error({
          message: res.msg
        });
      } else {
        this.files = res.data;
        this.form.file = res.data.url;
        this.$message.success({
          message: '上传文件成功！'
        });
      }
    },
    handleImgSuccess(res) {
      console.log(res);
      if (res.code == 1) {
        this.$message.error({
          message: res.msg
        });
      } else {
        this.form.img = res.data.url;
        this.$message.success({
          message: '上传文件成功！'
        });
      }
    },
    //enter键提交
    keyDown(e) {
      //console.log('enter');
      if (e.keyCode == 13 || e.keyCode == 100) {
        this.submit();
      }
    },
    //获取 栏目
    async getCategory() {
      await article.getCategoryNameList({
        ident: 'no'
      }).then(res => {
        this.allCats = res.data || [];
        this.loading = false;
      })
    },
    //获取 类型
    async getType() {
      await article.getCategoryNameList({
        ident: 'type'
      }).then(res => {
        this.type = res.data || [];
        this.loading = false;
      })
    },
    //获取 行业
    async getBusiness() {
      await article.getCategoryNameList({
        ident: 'business'
      }).then(res => {
        this.business = res.data || [];
        this.loading = false;
      })
    },
    //获取 品牌
    async getBrand() {
      await article.getCategoryNameList({
        ident: 'brand'
      }).then(res => {
        this.brand = res.data || [];
        this.loading = false;
      })
    },
    async submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          article.edit(this.form).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.$router.push('/user/home');
            }
          });
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>